import React, { useState, useEffect } from 'react';
import { Button, Box, Loader, Modal, NumberInput, Stack } from '@mantine/core';
import { useDialogContext } from '~/components/Dialog/DialogProvider';
import { trpc } from '~/utils/trpc';
import { ReviewReactions } from '@prisma/client';
import { showErrorNotification, showSuccessNotification } from '~/utils/notifications';

type AdjustReactionsDialogProps = {
  id: number;
  type: 'model' | 'image';
};

export function AdjustReactionsDialog({ id, type }: AdjustReactionsDialogProps) {
  const queryUtils = trpc.useUtils();

  const dialog = useDialogContext();
  const [reactions, setReactions] = useState({
    heartCount: 0,
    likeCount: 0,
    dislikeCount: 0,
    laughCount: 0,
    cryCount: 0,
  });

  const [initialReactions, setInitialReactions] = useState(reactions); // Track initial values
  const [changed, setChanged] = useState({
    heartCount: false,
    likeCount: false,
    dislikeCount: false,
    laughCount: false,
    cryCount: false,
  });

  const { mutate: updateReactionCounts } = trpc.image.updateReactionCounts.useMutation();
  const { data, isLoading, refetch } = trpc.image.getReactionCounts.useQuery(
    { id, type },
    {
      enabled: false, // Disable automatic fetching
    }
  );

  useEffect(() => {
    if (dialog.opened) {
      refetch();
    }
  }, [dialog.opened, refetch]);

  const handleConfirm = async (field: keyof typeof reactions) => {
    const reactionType = mapFieldToReactionType(field);

    // Reset the changed state before calling the mutation
    setChanged((prev) => ({ ...prev, [field]: false }));

    try {
      updateReactionCounts({
        id,
        type,
        reactionType,
        count: reactions[field],
      });

      showSuccessNotification({ message: `Reaction counts updated successfully.` });
      if (type === 'image') {
        await queryUtils.image.get.invalidate({ id });
        await queryUtils.image.getInfinite.invalidate();
      } else if (type === 'model') {
        await queryUtils.model.getById.invalidate({ id });
        await queryUtils.model.getAll.invalidate();
      }
    } catch (error) {
      // Handle error scenarios
      showErrorNotification({
        title: 'Failed to update reaction counts.',
        error: new Error(),
        autoClose: false,
      });

      // Mark the field as "changed" again to indicate failure
      setChanged((prev) => ({ ...prev, [field]: true }));
    }
  };

  // Fetch reaction counts on mount
  useEffect(() => {
    if (data) {
      setReactions(data);
      setInitialReactions(data); // Set initial reactions to track changes
    }
  }, [data]);

  const handleChange = (field: keyof typeof reactions, value: number) => {
    setReactions((prev) => ({ ...prev, [field]: value }));
    setChanged((prev) => ({
      ...prev,
      [field]: value !== initialReactions[field], // Compare with initial value
    }));
  };

  const mapFieldToReactionType = (field: keyof typeof reactions) => {
    switch (field) {
      case 'heartCount':
        return ReviewReactions.Heart;
      case 'likeCount':
        return ReviewReactions.Like;
      case 'dislikeCount':
        return ReviewReactions.Dislike;
      case 'laughCount':
        return ReviewReactions.Laugh;
      case 'cryCount':
        return ReviewReactions.Cry;
      default:
        throw new Error('Invalid reaction field');
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          top: 0,
          opacity: 0.8,
          background: 'black',
          position: 'absolute',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Modal centered title="Adjust Reaction Count" opened onClose={dialog.onClose}>
      <Stack spacing="xs" p="md" style={{ margin: '0 auto' }}>
        <Box style={{ width: '100%' }}>
          <NumberInput
            label="❤️ Heart"
            value={reactions.heartCount}
            onChange={(value) => handleChange('heartCount', value!)}
            rightSection={
              <Button
                style={{ width: '100%' }}
                disabled={!changed.heartCount}
                onClick={() => handleConfirm('heartCount')}
              >
                Change
              </Button>
            }
            styles={{
              rightSection: {
                width: '90px',
              },
            }}
            style={{ width: '100%' }}
          />
        </Box>
        <Box style={{ width: '100%' }}>
          <NumberInput
            label="👍 Like"
            value={reactions.likeCount}
            onChange={(value) => handleChange('likeCount', value!)}
            rightSection={
              <Button
                style={{ width: '100%' }}
                disabled={!changed.likeCount}
                onClick={() => handleConfirm('likeCount')}
              >
                Change
              </Button>
            }
            styles={{
              rightSection: {
                width: '90px',
              },
            }}
            style={{ width: '100%' }}
          />
        </Box>
        <Box style={{ width: '100%' }}>
          <NumberInput
            label="👎 Dislike"
            value={reactions.dislikeCount}
            onChange={(value) => handleChange('dislikeCount', value!)}
            rightSection={
              <Button
                style={{ width: '100%' }}
                disabled={!changed.dislikeCount}
                onClick={() => handleConfirm('dislikeCount')}
              >
                Change
              </Button>
            }
            styles={{
              rightSection: {
                width: '90px',
              },
            }}
            style={{ width: '100%' }}
          />
        </Box>
        <Box style={{ width: '100%' }}>
          <NumberInput
            label="😂 Laugh"
            value={reactions.laughCount}
            onChange={(value) => handleChange('laughCount', value!)}
            rightSection={
              <Button
                style={{ width: '100%' }}
                disabled={!changed.laughCount}
                onClick={() => handleConfirm('laughCount')}
              >
                Change
              </Button>
            }
            styles={{
              rightSection: {
                width: '90px',
              },
            }}
            style={{ width: '100%' }}
          />
        </Box>
        <Box style={{ width: '100%' }}>
          <NumberInput
            label="😢 Cry"
            value={reactions.cryCount}
            onChange={(value) => handleChange('cryCount', value!)}
            rightSection={
              <Button
                style={{ width: '100%' }}
                disabled={!changed.cryCount}
                onClick={() => handleConfirm('cryCount')}
              >
                Change
              </Button>
            }
            styles={{
              rightSection: {
                width: '90px',
              },
            }}
            style={{ width: '100%' }}
          />
        </Box>
      </Stack>
    </Modal>
  );
}
