import { dialogStore } from '~/components/Dialog/dialogStore';
import { AdjustReactionsDialog } from '~/components/Dialog/Common/AdjustReactionsDialog';

type AdjustReactionInput = {
  id: number;
  type: 'model' | 'image';
};

export function useAdjustReactionCount() {
  return function ({ id, type }: AdjustReactionInput) {
    dialogStore.trigger({
      component: AdjustReactionsDialog,
      props: {
        id,
        type,
      },
    });
  };
}
